.fc-basic-view .fc-body .fc-row {
    min-height: 2em;
}

.fc-timegrid-slot {
    height: 5em !important; // 1.5em by default
    border-bottom: 0 !important;
}

.fc-timegrid,
.fc-scrollgrid {
    border: none
}

.App .fc-v-event {
    border-left: 4px solid rgba(0, 0, 0, 0.3) !important;
    cursor: pointer;
}