.menu .active {
    transition: background-color 0.7s
}

.menu::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.menu::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}

/* Handle */
.menu::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    ;
    border-radius: 10px;
}

/* Handle on hover */
.menu::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
}

.menu {
    z-index: 1000;
    font-size: 1rem;
}