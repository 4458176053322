html, body{

}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Nunito', sans-serif !important;
  font-size: 0.9rem !important;
  background-color: #fff !important;
}

.App {
  transition: all 0.5s;
  --fc-event-border-color: 'rgba(0,0,0,0.3)';
  --fc-event-bg-color: #00502f;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.cursor-pointer {
  cursor: pointer;
}

$primary: #00502f;
$secondary: #68bd45;

$font-size-root: 1.0rem;
$dropdown-link-hover-bg: '#fff';
$dropdown-link-active-bg: '#fff';


@import "../Assets/scss/bootstrap.scss";
@import "./bootstrap.theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

.m-xs-0 {

  @media (max-width: 576px) {
    margin: 0 !important;
    padding: 0 !important;
  }
}


.bg-sm-white {

  @media (min-width: 576px) {
    background-color: #fff;
  }
}


.btn:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: #ced4da;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
  outline: 0 none;
}

p{
  font-size: 1rem;
}


.noselect, .container-native {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a {
  text-decoration: none;
}
.btn-outline {
&:hover{
  background: transparent !important;
  color: #fff !important;
}
}

html {
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  --ion-safe-area-left: env(safe-area-inset-left);
  --ion-safe-area-right: env(safe-area-inset-right);
}

.bg-grey-300{
  background-color: $gray-300;
}


.w-md-auto{
  @media (min-width: 768px) {
    width: auto !important
  }

}
