.scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    .tab-item {
        display: inline-block;
    }
}

.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .tab-item {
        flex: 0 0 auto;
        margin-right: 3px;
    }
}

.tab-item {

    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: transparent;
    height: 51px;
    float: left;
}



.scrolling-wrapper,
.scrolling-wrapper-flexbox {
    width: 100%;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
}

.tab-item-selected {
    border-bottom-width: 3px;
    border-color: #00502f;
    border-bottom-style: solid
}